import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import SectorsFilter from "./SectorsFilter";

export default class Sector {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.sectorToken = "";
    this.parentSectorToken = "";
    this.parentSectorNameCurrent = "";
    this.managerEmployeeToken = "";
    this.managerEmployeeNameCurrent = "";
    this.branchToken = "";
    this.branchNameCurrent = "";
    this.sectorNameCollection = "";
    this.sectorNameCurrent = "";
    this.sectorNameAr = "";
    this.sectorNameEn = "";
    this.sectorNameUnd = "";
    this.sectorNotes = "";
    this.sectorArchiveStatus = false;
  }
  fillData(data) {
    this.sectorToken = data.sectorToken;
    this.parentSectorToken = data.parentSectorToken;
    this.parentSectorNameCurrent = data.parentSectorNameCurrent;
    this.managerEmployeeToken = data.managerEmployeeToken;
    this.managerEmployeeNameCurrent = data.managerEmployeeNameCurrent;
    this.branchToken = data.branchToken;
    this.branchNameCurrent = data.branchNameCurrent;
    this.sectorNameCollection = data.sectorNameCollection;
    this.sectorNameCurrent = data.sectorNameCurrent;
    this.sectorNameAr = data.sectorNameAr;
    this.sectorNameEn = data.sectorNameEn;
    this.sectorNameUnd = data.sectorNameUnd;
    this.sectorNotes = data.sectorNotes;
    this.sectorArchiveStatus = data.sectorArchiveStatus;
  }

  async getAllSectors(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new SectorsFilter()
  ) {
    try {
      return await axios.get(
        `/Sectors/GetAllSectors?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&branchToken=${filterData.branchToken}&parentSectorToken=${filterData.parentSectorToken}&managerEmployeeToken=${filterData.managerEmployeeToken}&textSearch=${filterData.textSearch}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfSectors(
    language,
    userAuthorizeToken,
    filterData = new SectorsFilter()
  ) {
    return await axios.get(
      `/Sectors/GetDialogOfSectors?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&branchToken=${filterData.branchToken}&parentSectorToken=${filterData.parentSectorToken}&managerEmployeeToken=${filterData.managerEmployeeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getSectorDetails(language, userAuthorizeToken) {
    try {
      return await axios.get(
        `/Sectors/GetSectorDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&sectorToken=${this.sectorToken}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateSector(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      sectorToken: this.sectorToken,
      branchToken: this.branchToken,
      parentSectorToken: this.parentSectorToken,
      managerEmployeeToken: this.managerEmployeeToken,
      sectorNameEn: this.sectorNameEn,
      sectorNameAr: this.sectorNameAr,
      sectorNameUnd: this.sectorNameUnd,
      sectorNotes: this.sectorNotes,
    };

    try {
      if (this.sectorToken == "" || this.sectorToken == undefined) {
        return await axios.post(`/Sectors/AddSector`, data);
      } else {
        return await axios.post(`/Sectors/UpdateSector`, data);
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveSector(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      sectorToken: this.sectorToken,
    };

    try {
      return await axios.post(`/Sectors/ArchiveSector`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
