import PaginationData from "@/models/general/PaginationData";
import Sector from "./Sector";
import SectorsFilter from "./SectorsFilter";

export default class Sectors {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.sectorsData = [];
    this.sector = new Sector();
    this.filterData = new SectorsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.sectorsPagination);
    this.sectorsData = data.sectorsPagination.sectorsData;
  }
}
