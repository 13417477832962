<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateSector()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'sectorNameAr'"
            :value="sector.sectorNameAr"
            v-on:changeValue="sector.sectorNameAr = $event"
            :title="$t('Sectors.nameAr')"
            :imgName="'sectors.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'sectorNameEn'"
            :value="sector.sectorNameEn"
            v-on:changeValue="sector.sectorNameEn = $event"
            :title="$t('Sectors.nameEn')"
            :imgName="'sectors.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'sectorNameUnd'"
            :value="sector.sectorNameUnd"
            v-on:changeValue="sector.sectorNameUnd = $event"
            :title="$t('Sectors.nameUnd')"
            :imgName="'sectors.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'branchToken'"
            :value="sector.branchToken"
            :options="branchTokenOptions"
            v-on:changeValue="sector.branchToken = $event"
            :title="$t('Sectors.branchSelect')"
            :imgName="'branches.svg'"
          />

          <CustomSelectBox
            :className="'col-md-6'"
            :id="'parentSectorToken'"
            :value="sector.parentSectorToken"
            :options="sectorTokenOptions"
            v-on:changeValue="sector.parentSectorToken = $event"
            :title="$t('Sectors.parentSectorSelect')"
            :imgName="'sectors.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'managerEmployeeToken'"
            :value="sector.managerEmployeeToken"
            :options="employeeTokenOptions"
            v-on:changeValue="sector.managerEmployeeToken = $event"
            :title="$t('Sectors.managerEmployeeSelect')"
            :imgName="'employees.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'sectorNotes'"
            :value="sector.sectorNotes"
            v-on:changeValue="sector.sectorNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Sectors' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";
import { STATUS } from "@/utils/constants";
import Employees from "@/models/employees/employees/Employees";
import Branch from "@/models/branches/Branch";
import Sectors from "@/models/settings/settingsOther/sectors/Sectors";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employees: new Employees(),
      employeeTokenOptions: [],
      branch: new Branch(),
      branchTokenOptions: [],
      sectors: new Sectors(),
      sectorTokenOptions: [],
    };
  },
  props: ["sector", "submitName"],
  methods: {
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("Sectors.managerEmployeeSelect"),
      });
      try {
        const response = await this.employees.employee.getDialogOfEmployees(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfBranches() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      this.branchTokenOptions.push({
        value: "",
        text: this.$t("Sectors.branchSelect"),
      });
      try {
        const response = await this.branch.getDialogOfBranches(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfSectors() {
      this.isLoading = true;
      this.sectorTokenOptions = [];
      this.sectorTokenOptions.push({
        value: "",
        text: this.$t("Sectors.parentSectorSelect"),
      });
      try {
        const response = await this.sectors.sector.getDialogOfSectors(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.sectorTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    addOrUpdateSector() {
      this.$emit("addOrUpdateSector");
    },
  },
  watch: {},
  created() {
    this.getDialogOfEmployees();
    this.getDialogOfBranches();
    this.getDialogOfSectors();
  },
};
</script>
