export default class SectorsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.branchToken = "";
    this.parentSectorToken = "";
    this.managerEmployeeToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.branchToken = data.branchToken;
    this.parentSectorToken = data.parentSectorToken;
    this.managerEmployeeToken = data.managerEmployeeToken;
    this.textSearch = data.textSearch;
  }
}
